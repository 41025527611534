@{global-scope} {
    .@{ant-prefix}-btn-round {
        font-size: 12px;
        font-weight: 500;
    }


    .@{ant-prefix}-btn-round.@{ant-prefix}-btn-lg {
        font-size: 14px;
        font-weight: 500;
        height: 46px;
    }

    .@{ant-prefix}-btn-circle.@{ant-prefix}-btn-lg {
        font-size: 14px;
        font-weight: 500;
        height: 46px;
        width: 46px;
        background-color: transparent;
        border-color: @primary-color;
    }

    .@{ant-prefix}-btn-circle.@{ant-prefix}-btn-lg.filled-circle-button {
        background-color: @color-primary-light;
        border-color: transparent;
        color: @primary-color
    }


    .@{ant-prefix}-btn-circle.filled-circle-button.btn-big {
        width: 60px;
        height: 60px;
        font-size: 20px;
    }

    .contain-button {
        height: auto
    }


    .@{ant-prefix}-btn-round.btn-largest {
        height: 60px;
        border-radius: 30px;
        font-size: 18px;
    }


    @media (min-width: @screen-sm-min) {
        .@{ant-prefix}-btn-circle.filled-circle-button.btn-largest {
            min-width: 80px;
            min-height: 80px;
            width: 80px;
            height: 80px;
            font-size: 26px;
        }
    }

    @media (min-width: @screen-md-min) {
        .@{ant-prefix}-btn-circle.filled-circle-button.btn-largest {
            min-width: 40px;
            min-height: 40px;
            width: 40px;
            height: 40px;
            font-size: 16px;
        }
    }

    @media (min-width: @screen-lg-min) {
        .@{ant-prefix}-btn-circle.filled-circle-button.btn-largest {
            min-width: 60px;
            min-height: 60px;
            width: 60px;
            height: 60px;
            font-size: 20px;
        }
    }

    @media (min-width: @screen-xl-min) {
        .@{ant-prefix}-btn-circle.filled-circle-button.btn-largest {
            min-width: 80px;
            min-height: 80px;
            width: 80px;
            height: 80px;
            font-size: 26px;
        }
    }

    .@{ant-prefix}-btn-circle.filled-circle-button.btn-borderd {
        border-color: @primary-color
    }

    .@{ant-prefix}-btn.@{ant-prefix}-btn-circle.download-button {
        display: flex;
        flex-direction: column;
        align-items: center;

        span:not([role=img]) {
            margin-left: 0;
        }
    }
}