@import '~antd/lib/style/themes/default.less';
@import './buttons.less';
@import './inputs.less';
@import './date-picker.less';
@import './dropdown.less';

@success-color: #2ac642;
@color-primary-light: #f1f4ff;
@light-border-color: #d9d9d9;
@normal-color: #fafafa;

@{global-scope} {
    .success {
        color: @success-color;
    }

    .primary {
        color: @primary-color !important;
    }

    .danger {
        color: @error-color;
        fill: @error-color;
    }

    .danger-light {
        color: rgba(255, 71, 128, 0.17);
        fill: rgba(255, 71, 128, 0.17);
    }

    .bg-danger-light {
        background-color: rgba(255, 71, 128, 0.17);
    }

    .border-danger-light {
        border-color: rgba(255, 71, 128, 0.17);
    }

    .border-transparent {
        border-color: transparent;
    }

    .default {
        color: #3e3e3e;
    }

    .@{ant-prefix}-layout .@{ant-prefix}-layout-header {
        display: flex;
        align-items: center;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
        padding: 0;
    }

    .@{ant-prefix}-layout-content {
        padding: 38px 0;
        padding-top: 40px;
    }

    .@{ant-prefix}-layout-footer {
        text-align: right;
        position: fixed;
        bottom: 0;
        width: 100%;
        background: white;
        padding: 0;
        z-index: 2;
    }

    .navigation-menu {
        .navigation-item {
            min-width: auto;
            width: 100%;
            display: block;
            height: auto;
            white-space: normal;
            padding: 10px 15px 10px 0;
            border-radius: 0;
            border: none;
            margin-bottom: 24px;

            .navigation-item-icon {
                stroke: #3e3e3e;
                fill: #3e3e3e;
            }

            .navigation-item-label {
                line-height: 1.2;
                margin-top: 8px;
                margin-bottom: 0;
                hyphens: auto;
            }

            .navigation-item-label-horizontal {
                margin-top: 4px;
            }
        }

        .navigation-item:hover {
            .navigation-item-label {
                color: @primary-color;
            }

            .navigation-item-icon {
                fill: @primary-color;
                stroke: @primary-color;
            }
        }

        .navigation-item-horizontal {
            padding: 10px 0 10px 0;
            margin-bottom: 0;
        }

        .nav-link-horizontal {
            display: flex;
            flex: 1;
        }

        .nav-link-disabled {
            opacity: 0.5;
        }

        .selected {
            .navigation-item {
                border-right: 3px @primary-color solid;

                .navigation-item-icon {
                    stroke: @primary-color;
                    fill: @primary-color;
                }

                .navigation-item-label {
                    color: @primary-color;
                }
            }

            .navigation-item-horizontal {
                padding: 10px 10px 10px 10px;
                border-right: none;
                border-bottom: 3px @primary-color solid;
            }
        }
    }

    .navigation-menu-horizontal {
        display: flex;
    }

    .flexible {
        display: flex;
        align-items: center;
    }

    .lg,
    .lg.@{ant-prefix}-checkbox-wrapper {
        span {
            font-weight: bold;
        }

        .@{ant-prefix}-checkbox {
            .@{ant-prefix}-checkbox-inner {
                height: 22px;
                width: 22px;
            }
        }

        .@{ant-prefix}-checkbox-checked .@{ant-prefix}-checkbox-inner::after {
            width: 8px;
            height: 14px;
        }
    }

    .view {
        display: flex;
    }

    .@{ant-prefix}-card {
        border-radius: 10px;
    }

    .@{ant-prefix}-card-meta {
        line-height: 1.3;
    }

    .@{ant-prefix}-card-meta-title {
        line-height: 1;
        margin-bottom: 0;
        color: @disabled-color;
        font-weight: bold;
        font-size: 14px;
    }

    .@{ant-prefix}-card-meta-description {
        color: @heading-color;
        font-weight: bold;
        font-size: 16px;
    }

    .header-page {
        font-size: 40px;
        font-weight: 900;
    }

    .@{ant-prefix}-upload.@{ant-prefix}-upload-drag {
        border-radius: 10px;
        min-height: 180px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;
    }

    & .@{ant-prefix}-spin-container.@{ant-prefix}-spin-blur {
        overflow: visible;
    }

    //tables

    .@{ant-prefix}-table {
        background: transparent;

        .@{ant-prefix}-table-container {
            &::after,
            &::before {
                z-index: 4;
            }
        }
    }

    .@{ant-prefix}-table-wrapper {
        .@{ant-prefix}-pagination {
            &-total-text {
                padding-left: 6px;
            }

            float: right;
            margin-right: 10px;
        }
    }

    .@{ant-prefix}-table-placeholder {
        background: transparent;
        border: none;
    }

    .@{ant-prefix}-table-small {
        border: none;
    }

    .table-rounded {
        table {
            border-spacing: 0 5px;

            thead {
                .@{ant-prefix}-table-cell {
                    border-bottom: 0;
                    background-color: white;
                    color: gray;
                }
            }
        }

        .@{ant-prefix}-table-row {
            .@{ant-prefix}-table-cell {
                border-bottom: 0;
                background-color: #fafafa;
                font-size: 14px;
                font-weight: 900;
            }

            .@{ant-prefix}-table-cell:first-child {
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
            }

            .@{ant-prefix}-table-cell:last-child {
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
            }

            &:hover {
                .@{ant-prefix}-table-cell {
                    background-color: #f5f5f5;
                }
            }
        }

        .@{ant-prefix}-table-row-level-1 {
            .@{ant-prefix}-table-cell {
                background-color: transparent;
                border-bottom: 1px solid #fafafa;
            }

            .indent-level-1 {
                padding: 0 !important;
            }

            .@{ant-prefix}-table-cell:first-child {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }

            .@{ant-prefix}-table-cell:last-child {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        .@{ant-prefix}-table-row.inactive {
            color: gray;
        }
    }

    //collaps

    .@{ant-prefix}-collapse {
        background-color: transparent;
        border: 0;

        .@{ant-prefix}-collapse-item,
        .@{ant-prefix}-collapse-item:last-child {
            border: 0;
            margin-bottom: 5px;

            .@{ant-prefix}-collapse-header,
            .@{ant-prefix}-collapse-header:last-child {
                border: 0;
                border-radius: 10px;
                background-color: #fafafa;
                font-weight: bold;
            }

            .@{ant-prefix}-collapse-content {
                border: 0;
            }
        }
    }

    .@{ant-prefix}-drawer-header {
        border: 0;

        .@{ant-prefix}-drawer-title {
            margin-top: 30px;
        }

        .@{ant-prefix}-drawer-close {
            background-color: #e9e9e9;
            border-radius: 20px;
            width: 40px;
            height: 40px;
            top: 16px;
            right: 16px;
            line-height: 40px;
            padding: 0;
        }
    }

    .@{ant-prefix}-drawer-body {
        .drawer-footer {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 100%;
            border-top: 1px solid #e9e9e9;
            padding: 10px 16px;
            background: #fff;
            text-align: right;
        }
    }

    //Popover

    .@{ant-prefix}-popover-inner {
        border-radius: 10px;
        overflow: hidden;

        .footer {
            border-top: 1px solid #f9f9f9;
        }
    }

    .custom-ant-menu.@{ant-prefix}-menu.@{ant-prefix}-menu-root.@{ant-prefix}-menu-inline {
        border-right: none;

        .@{ant-prefix}-menu-submenu-title {
            border-radius: 10px;
            font-weight: 600;

            &:hover {
                background-color: #fafafa;
            }
        }

        .@{ant-prefix}-menu-item {
            border-radius: 10px;
            font-weight: 600;

            &:first-child {
                margin-top: 0;
            }

            &:hover {
                background-color: #fafafa;
            }

            &-selected {
                background-color: #f5f5f5 !important;
            }

            &::after {
                display: none;
            }
        }
    }

    .main-container {
        padding: 10px 0;

        .@{ant-prefix}-table-content {
            overflow-x: auto;
        }
    }

    .main-container.with-navigation {
        padding-left: 20px;
    }

    .form-container {
        background-color: rgba(231, 237, 255, 0.6);
        max-width: 500px;
        min-height: 430px;
        border-radius: 15px;
        padding: 40px;
        flex: 1;

        .form-header {
            display: flex;
            margin-bottom: 20px;

            .form-title-container {
                flex: 1;
                width: 258px;
                padding-right: 20px;

                .form-title {
                    font-size: 20px;
                    line-height: 1.3;
                }
            }

            .form-step {
                line-height: 0.9;

                .form-step-active {
                    color: @primary-color;
                    font-weight: 900;
                    font-size: 58px;
                }

                .form-step-total {
                    font-size: 31px;
                    font-weight: 900;
                }
            }
        }

        .@{ant-prefix}-legacy-form-item-control-wrapper {
            flex: 0 1 auto;
        }
    }

    th.column-numeric,
    td.column-numeric {
        text-align: right !important;
    }

    .@{ant-prefix}-table tbody tr td.column-vertical-fill {
        padding-top: 0;
        padding-bottom: 0;
    }

    th.no-border,
    td.no-border {
        border: 0 !important;
    }

    .timeline {
        margin-left: 120px;

        .amount {
            display: inline-block;
            width: 100px;
            text-align: right;
            margin-left: -136px;
            font-weight: bold;
            margin-right: 50px;
        }

        .timeline-item-date {
            .@{ant-prefix}-timeline-item-content {
                width: 200px;
                text-align: center;
                margin-left: -95px;
                font-weight: bold;
                background-color: white;
            }

            .@{ant-prefix}-timeline-item-tail {
                height: 100%;
                top: 0;
            }

            .@{ant-prefix}-timeline-item-head {
                opacity: 0;
            }
        }

        .@{ant-prefix}-timeline-item {
            .day {
                position: absolute;
                left: -100px;
                width: 72px;
                text-align: right;
            }
        }

        .timeline-item-date-grouped {
            padding-bottom: 10px;
        }
    }

    .block {
        padding: 24px;
        border-radius: 15px;

        .block-secondary {
            background-color: rgba(208, 208, 208, 0.14);
        }
    }

    .square {
        border-radius: 0;
    }

    .block.block-primary {
        background-color: rgb(241, 244, 254);
    }

    .block.block-disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    .margin-bottom-05 {
        margin-bottom: 6px;
    }

    .margin-bottom {
        margin-bottom: 12px;
    }

    .margin-bottom-2x {
        margin-bottom: 24px;
    }

    .cancel-margin-bottom {
        margin-bottom: 0 !important;
    }

    .margin-top {
        margin-top: 12px;
    }

    .margin-top-2x {
        margin-top: 24px;
    }

    .margin-left {
        margin-left: 12px;
    }

    .margin-left-2x {
        margin-left: 24px;
    }

    .margin-right {
        margin-right: 12px;
    }

    .margin-right-2x {
        margin-right: 24px;
    }

    .light-form {
        .@{ant-prefix}-form-item-label label {
            color: @text-color;
        }

        .@{ant-prefix}-input-password input.@{ant-prefix}-input {
            padding-left: 0;
        }

        .@{ant-prefix}-input,
        .@{ant-prefix}-input-affix-wrapper {
            border-top: 0;
            border-left: 0;
            border-right: 0;
            border-radius: 0;
            border-bottom: solid 1px @border-color-base;
            padding-left: 0;
            padding-right: 0;
        }

        .react-tel-input.custom {
            display: inline-block;

            .form-control {
                width: 100%;
                border-top: 0;
                border-left: 0;
                border-right: 0;
                border-radius: 0;
                border-bottom: solid 1px @border-color-base;
                height: 38px;
            }

            .flag-dropdown,
            .flag-dropdown.open,
            .flag-dropdown:hover,
            .selected-flag {
                background-color: transparent;
                border: 0;
            }
        }

        .has-error {
            .input {
                border-color: @error-color;
            }

            .react-tel-input.custom {
                .form-control {
                    border-color: @error-color;
                }
            }
        }
    }

    .bordered-form {
        .@{ant-prefix}-form-item-label label {
            color: @text-color;
        }

        .@{ant-prefix}-input,
        .@{ant-prefix}-input-affix-wrapper {
            border-radius: 10px;
            border: solid 1px @border-color-base;
            height: 38px;
            padding-top: 0;
            padding-bottom: 0;

            input {
                height: auto;
            }
        }

        .@{ant-prefix}-input-affix-wrapper {
            .@{ant-prefix}-input {
                border: none;
            }
        }

        .@{ant-prefix}-select.@{ant-prefix}-select-single,
        .@{ant-prefix}-picker {
            border-radius: 10px;
            border: solid 1px @border-color-base;
        }

        .react-tel-input.custom {
            border: solid 1px @border-color-base;

            .form-control {
                height: 36px;
            }
        }

        .has-error {
            .input {
                border-color: @error-color;
            }

            .react-tel-input.custom {
                .form-control {
                    border-color: @error-color;
                }
            }
        }
    }

    .light-input-bordered-form {
        .@{ant-prefix}-input {
            border: 0.2px solid @light-border-color !important;
            font-weight: normal !important;
        }
    }

    .with-descriptions-bold-label {
        .@{ant-prefix}-descriptions-item-label.@{ant-prefix}-descriptions-item-colon {
            font-weight: 700;
        }
    }

    .clickable-row-table {
        .@{ant-prefix}-table-row {
            cursor: pointer;
        }
    }

    .expandable-separators-table {
        table tbody {
            .@{ant-prefix}-table-expanded-row-level-1 {
                td.@{ant-prefix}-table-cell {
                    padding: 0;
                    height: 8px;
                    background: transparent;
                    border: none;
                }

                &:last-child {
                    td.@{ant-prefix}-table-cell {
                        height: 0;
                    }
                }
            }
        }
    }

    .nowrap-thead-table {
        thead {
            th {
                white-space: nowrap;
            }
        }
    }

    .select-payment > .ant-select-selector {
        background-color: #fff;
        border: none;
        border-radius: 10px;
        padding: 5px;
    }

    .select-payment > .ant-select-selector > .ant-select-selection-item {
        background-color: @primary-color;
        border: none;
        border-radius: 6px;
        color: #fff;
        font-size: 16px;
        font-weight: 400;
    }

    .ant-select-multiple .ant-select-selection-item-remove svg {
        fill: #fff;
        width: 12px;
        height: 12px;
        position: relative;
        top: 1px;
    }

    .flat-footer {
        background-color: white;
        position: sticky;
        padding: 10px 30px;
        margin-top: 55px;
        bottom: 40px;
        height: 66px;
        border-radius: 40px;
        -webkit-box-shadow: 0 0 51px 24px rgba(0, 0, 0, 0.05);
        -moz-box-shadow: 0 0 51px 24px rgba(0, 0, 0, 0.05);
        box-shadow: 0 0 51px 24px rgba(0, 0, 0, 0.05);
    }

    @media (max-width: @screen-sm-max) {
        .flat-footer {
            bottom: 70px;
        }
    }

    .padding {
        padding: 24px;
    }

    .padding-2x {
        padding: 48px;
    }

    .padding-none {
        padding: 0;
    }

    .table-group {
        & > :not(:first-child) {
            thead {
                visibility: collapse;
            }

            margin-top: 10px;
        }
    }

    .not-found {
        background-color: #fbfbfb !important;
        display: flex;
        justify-content: center !important;
        align-items: center !important;
        font-size: 24px;
        font-weight: 500;
        min-height: 100%;
        border-radius: 20px;
    }

    .country-picker {
        .ant-select-selector {
            .@{ant-prefix}-select-selection-placeholder {
                line-height: 38px;
            }
        }
    }

    .mw-300 {
        max-width: 300px;
    }

    .text-overflow-ellipsis {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .@{ant-prefix}-checkbox-group {
        .@{ant-prefix}-checkbox-group-item:not(:first-child) {
            margin-top: 4px;
        }
    }

    @media (max-width: @screen-sm-max) {
        .hidden-sm {
            display: none !important;
        }

        .form-container {
            padding: 20px;
        }
    }

    @media (max-width: @screen-md-max) {
        .hidden-md {
            display: none !important;
        }
    }

    @media (max-width: @screen-lg-max) {
        .hidden-lg {
            display: none !important;
        }
    }

    @media (max-width: @screen-xl-max) {
        .hidden-xl {
            display: none !important;
        }
    }

    .@{ant-prefix}-table-filter-dropdown {
        border-radius: 10px;
    }

    .preview-mask {
        font-weight: normal;
        display: flex;
        align-items: center;
        padding: 5px;
        line-height: 0;

        & span[role='img'] {
            margin-right: 5px;
        }
    }
}

@ant-prefix: ant;@global-scope: .global-scope;@primary-color: #507de3;@error-color: #F9467E;@text-color: #475061;@heading-color: #3d3d3d;@text-disabled: #9a9da1;@layout-header-background: #F4F4F4;@layout-body-background: #F4F4F4;@layout-header-height: 74px;@tablet-size: 1050px;@mobile-size: 768px;@font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;