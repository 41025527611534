@{global-scope} {
    .@{ant-prefix}-form-item-explain {
        color: #3d3d3d;
        font-size: 12px;
        margin-top: 2px;
    }

    .@{ant-prefix}-form-item-label {
        margin-bottom: 4px;
        line-height: 1.5;
        white-space: normal;
        text-align: left;
    }

    .@{ant-prefix}-form-item {
        flex-direction: column;

        &.upload-files-form-item {
            .@{ant-prefix}-form-item-children-icon {
                margin-right: -30px;
                margin-top: 4px;
            }
        }
    }

    .@{ant-prefix}-select {
        &-dropdown {
            border-radius: 10px;
            padding: 6px 0;
        }

        &.@{ant-prefix}-select-single,
        &.@{ant-prefix}-select-multiple {
            .@{ant-prefix}-select-selector {
                border-radius: 10px;
                border-top: 0;
                border-left: 0;
                border-right: 0;
                border-bottom: 0;
                font-weight: bold;
                height: 38px;
                display: flex;
                align-items: center;
            }
        }

        &.@{ant-prefix}-select-multiple {
            .@{ant-prefix}-select-selector {
                padding-left: 6px;
            }

            .@{ant-prefix}-select-selection-item {
                border-radius: 10px;
                font-weight: normal;

                &-remove .anticon svg {
                    fill: #ccc;
                }
            }
        }
    }

    .@{ant-prefix}-select-selection,
    .@{ant-prefix}-select-selection--single {
        border-radius: 10px;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: 0;
        font-weight: bold;
        height: 38px;

        .@{ant-prefix}-select-selection__rendered {
            line-height: 38px;
        }
    }

    .@{ant-prefix}-calendar-range-picker-separator {
        vertical-align: unset;
    }

    .@{ant-prefix}-form-item-control-wrapper {
        width: 100%;
    }

    .@{ant-prefix}-form label,
    .@{ant-prefix}-form-item-label label {
        font-size: 14px;
        font-weight: bold;
    }

    .day-input {
        cursor: pointer;
        display: inline-flex;
        min-width: 120px;
    }

    .@{ant-prefix}-input,
    .@{ant-prefix}-input-password,
    .@{ant-prefix}-picker,
    .@{ant-prefix}-picker-focused {
        border-radius: 10px;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: 0;
        font-weight: bold;
        height: 38px;

        &.text-field-readonly {
            color: #3D3D3D;

            &_disabled {
                cursor: not-allowed !important;
            }
        }
    }

    .@{ant-prefix}-input-affix-wrapper {
        border-radius: 10px;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: 0;
        font-weight: bold;
    }

    .@{ant-prefix}-input-suffix {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .@{ant-prefix}-input-prefix {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .@{ant-prefix}-input-password {
        padding-top: 0;
        padding-left: 0;
        padding-bottom: 0;

        input.@{ant-prefix}-input {
            height: auto;
            padding: 4px 11px;
        }
    }

    .@{ant-prefix}-radio-checked .@{ant-prefix}-radio-inner::after {
        display: block;
    }

    .tag-input {
        display: inline-flex;
        flex-wrap: wrap;
        margin-bottom: -6px;

        .tag {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 6px;

            .tag-container {
                border-radius: 10px;
                border: 1px solid @primary-color;
                padding: 6px 12px;
                height: 38px;
                color: @primary-color;
            }
        }

        button {
            padding: 0 0 0 2px;
            height: unset;
            line-height: 1;
        }

        .add-tag {
            margin-bottom: 6px;

            button {
                height: 38px;
                width: 38px;
                margin-left: 10px;
                padding: 0;

                span {
                    display: flex;
                    font-size: 16px;
                    justify-content: center;
                }
            }
        }
    }

    .custom-radio.large {
        .ant-radio-inner {
            width: 26px;
            height: 26px;
        }

        .ant-radio-checked .ant-radio-inner::after {
            width: 16px;
            height: 16px;
            border-radius: 8px;
            top: 4px;
            left: 4px;
        }
    }

    .react-tel-input.custom {
        display: inline-block;
        border-radius: 10px;
        border: none;
        height: 38px;
        line-height: 1;

        .form-control {
            width: 100%;
            border-radius: 10px;
            height: 38px;
            background: #fff;
        }

        input {
            border: 0;
            font-weight: bold;
        }

        .flag-dropdown,
        .flag-dropdown.open,
        .flag-dropdown:hover,
        .selected-flag {
            background-color: transparent !important;
            border: 0;
        }
    }

    .verification-code-input {
        input {
            display: inline-block;
            border-radius: 10px !important;
            border: none !important;
            height: 38px !important;
            line-height: 1;
            caret-color: auto !important;
            width: 50px !important;
            margin-right: 5px;
            font-family: @font-family !important;
        }

        input:last-child {
            margin-right: 0;
        }
    }

    .image-upload-field {
        .@{ant-prefix}-form-item-control {
            line-height: 1;

            .ant-spin-nested-loading {
                display: inline-block;
            }
        }

        .ant-form-item-children-icon {
            display: none;
        }

        .@{ant-prefix}-upload.@{ant-prefix}-upload-select-picture-card {
            border-radius: 10px;
            border: 1px solid @light-border-color;
            position: relative;
            margin: 0;
            display: inline-block;
            width: auto;
            height: auto;

            .@{ant-prefix}-upload {
                padding: 0 !important;

                img {
                    border-radius: 10px;
                    object-fit: contain;
                }
            }

            .image-upload-button {
                border-radius: 10px;
                opacity: 0;
                position: absolute;
                right: -1px;
                bottom: -1px;
                top: -1px;
                left: -1px;
                background-color: rgba(70, 113, 255, 0.8);
                transition: opacity 0.23s ease-in-out;
                cursor: pointer;

                &--active {
                    opacity: 1;
                    position: relative;
                    top: 0;
                    left: 0;
                    margin: -1px;
                }
            }

            &:hover {
                .image-upload-button {
                    opacity: 1;
                }
            }
        }
    }

    .image-upload-preview-field {
        & .@{ant-prefix}-image-mask {
            border-radius: 10px;
            cursor: default;
        }

        & .@{ant-prefix}-upload.@{ant-prefix}-upload-select {
            border: 1px dashed #D9D9D9;
            transition: border 0.23s;

            &:hover {
                border: 1px dashed #6E94FF;
                transition: border 0.23s;
            }
        }

        &.@{ant-prefix}-form-item-has-error .@{ant-prefix}-upload.@{ant-prefix}-upload-select {
            &:hover {
                border: 1px dashed #F9467E;
            }
        }

        & .@{ant-prefix}-form-item-children-icon {
            display: block;
            top: 16px !important;
        }

        & .upload-field-ref,
        & .@{ant-prefix}-image-img {
            line-height: 0;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        & .upload-field-blank {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            height: 100%;
            cursor: pointer;
            user-select: none;

            &__icon {
                margin-bottom: 0.5em;
            }

            &__text {
                text-align: center;
                line-height: 1.5em;
                padding: 0 1em;
                white-space: pre-wrap;
            }
        }

        & .upload-preview-mask {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &__button {
                display: flex;
                align-items: center;
                padding: 5px;
                line-height: 0;

                & span[role='button'] {
                    margin-left: 5px;
                }
            }
        }
    }

    .numeric-field {
        display: flex;
        align-items: center;
        position: relative;

        &__button {
            width: 25px;
            height: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #D9D9D9;
            border-radius: 8px;
            background-color: rgba(0, 0, 0, 0.05);
            transition: transform 0.05s ease, background-color 0.3s ease;
            cursor: pointer;
            transition: 0.3s opacity;

            &:hover {
                background-color: rgba(0, 0, 0, 0.15);
                transition: background-color 0.3s ease;
            }

            &:active {
                transform: scale(0.9);
                transition: transform 0.05s ease;
            }

            &_disabled {
                pointer-events: none;
                transition: 0.3s opacity;
                opacity: 0.4;
            }
        }

        &__button-wrapper {
            position: absolute;
            z-index: 1;
            top: 7px;
            user-select: none;

            &_before {
                left: 9px;
            }

            &_after {
                right: 9px;
            }

            &_disabled {
                cursor: not-allowed;
            }
        }

        & > input.@{ant-prefix}-input {
            cursor: text;
            text-align: center;
            padding-left: 41px;
            padding-right: 41px;
        }

        & > .@{ant-prefix}-input-affix-wrapper {
            display: flex;
            justify-content: center;
            z-index: 0;
            cursor: text;
            padding-left: 41px;
            padding-right: 41px;

            & > input.@{ant-prefix}-input {
                transition: width 0s;
            }
        }

        & .@{ant-prefix}-input-suffix {
            user-select: none;
        }
    }

    .select-tag-field {
        & > .@{ant-prefix}-select-selector {
            height: auto !important;
            padding: 4px 32px 4px 6px !important;
        }

        & > .@{ant-prefix}-select-arrow {
            top: calc(50% + 1px);
            right: 14px;
        }

        & > .@{ant-prefix}-select-clear {
            font-size: 1em;
            right: 15px;
            margin-top: -7px;
        }

        & .@{ant-prefix}-select-selection-overflow-item-suffix {
            width: 0;
        }
    }

    .select-tag-field.@{ant-prefix}-select-disabled {
        & > .@{ant-prefix}-select-selector {
            cursor: not-allowed !important;
        }
    }
}
